import React, { useState } from 'react';
import Wrapper from './styles';
import { Link } from 'react-router-dom';
import { FaCheck } from 'react-icons/fa';
import { useCartContext } from '../../context/cart_context';
import AmountButtons from '../AmountButtons/';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import toast from 'react-hot-toast'
import { Button, Modal } from 'react-bootstrap';

const AddToCart = ({ product }) => {
  const history = useHistory();
  const { addToCart } = useCartContext();
  const { _id: id, stock, colors, sizes, sizeCharts } = product;
  const [mainColor, setMainColor] = useState(colors[0]);
  const [mainSize, setMainSize] = useState(sizes[0]);
  const [amount, setAmount] = useState(1);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [selectedSizeCharts, setSelectedSizeCharts] = useState("");

  const handleShow = (sizeChart) => {
    setSelectedSizeCharts(sizeChart);
    setShow(true);
  };

  console.log(sizeCharts, selectedSizeCharts, "sizeCharts");

  const increase = () => {
    setAmount((oldAmount) => {
      let tempAmount = oldAmount + 1;
      if (tempAmount > stock) {
        tempAmount = stock;
      }
      return tempAmount;
    });
  };

  const handleCheckoutPage = () => {
    history.push('/checkout');
  }

  const handleClickBuyNow = () => {
    addToCart(id, mainColor, mainSize, amount, product);
    localStorage.setItem('buy_now_order_id', id + mainColor + mainSize)
    handleCheckoutPage();
  };

  const decrease = () => {
    setAmount((oldAmount) => {
      let tempAmount = oldAmount - 1;
      if (tempAmount < 1) {
        tempAmount = 1;
      }
      return tempAmount;
    });
  };

  const [selectedSize, setSelectedSize] = useState("");

  const handleSelect = (event) => {
    console.log("hello",);
    setSelectedSize(event.target.value);
  };

  console.log("hello size", mainSize);
  // const handleClick = () => {
  //   console.log("Selected size:", selectedSize);
  //   // You can do something with the selected size here
  // };

  return (
    <Wrapper>
      <div className='colors'>
        <span>colors : </span>
        <div>
          {colors.map((color, index) => {
            return (
              <button
                key={index}
                className={`${mainColor === color ? 'color-btn active' : 'color-btn'
                  }`}
                style={{ background: color }}
                onClick={() => {
                  setMainColor(colors[index]);
                }}
              >
                {mainColor === color && <FaCheck />}
              </button>
            );
          })}
        </div>
        <span>size : </span>
        <div>
          <select
            className='input sort-input'
            onChange={(e) => setMainSize(e.target.value)}
          >
            {sizes.map((size, index) => {
              return (
                <option key={index} value={size}>
                  {size}
                </option>
              );
            })}
          </select>
        </div>
      </div>
      <div className='btn-container product-detail-btn'>
        <div className='d-flex justify-content-between'>
          <AmountButtons
            amount={amount}
            increase={increase}
            decrease={decrease}
          />
          <div style={{ border: '1px solid silver', padding: 2, borderRadius: 5, cursor: 'pointer ' }} onClick={() => handleShow(sizeCharts)}>Size Chart</div>
        </div>
        <div className='product-btn-section add-cart' style={{ justifyContent: 'unset', gap: '20px' }}>
          <button className='add-to-cart-btn add mobile-margin-top' onClick={() => {
            addToCart(id, mainColor, mainSize, amount, product);
           
          }}
          >
            Add to Cart</button>
          <button className='add-to-cart-btn buy-now mobile-margin-top-zero' onClick={handleClickBuyNow} >BUY NOW</button>
        </div>
        {/* <Link
          to='/cart'
          className='btn'
          onClick={() => addToCart(id, mainColor, mainSize, amount, product)}
        >
          add to cart
        </Link> */}
      </div>
      <Modal show={show} onHide={handleClose} animation={false}>
        <Modal.Header>
          <Modal.Title>Size Guide</Modal.Title>
          <i class="fa fa-close" style={{fontSize: "24px"}} onClick={handleClose}></i>
        </Modal.Header>
        <Modal.Body>
          <img src={selectedSizeCharts[0]?.url} alt="" />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

    </Wrapper>
  );
};

export default AddToCart;
