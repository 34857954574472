import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function GoToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    const scrollToTop = () => {
      console.log(pathname, "pathname Anas");
      window.scrollTo(50, 0);
    };

    // Adding a slight delay to ensure content is rendered before scrolling
    const timeoutId = setTimeout(scrollToTop, 100);

    return () => clearTimeout(timeoutId); // Cleanup timeout on unmount
  }, [pathname]);

  return null;
}
