import React, { useEffect } from 'react';
import Wrapper from './styles';
import { PageHero } from '../../components';
import aboutImg from '../../assets/about-img.avif';

const AboutPage = () => {
  useEffect(() => {
    document.title = 'SRK STYLE| About';
  }, []);

  return (
    <main>
      <PageHero title='about' />
      <Wrapper className='page section section-center'>
        <img src={aboutImg} alt='pic' />
        <article>
          <div className='title'>
            <h2>My Story</h2>
            <div className='underline'></div>
          </div>

          <p><b>About Srk Style</b></p>

          <p> Welcome to Srk Style, your ultimate destination for fashion inspiration, trends, and lifestyle insights!</p>

          <p><b> Who We Are</b></p>

          <p>At Srk Style, we're a passionate team of fashion enthusiasts dedicated to bringing you the latest in style, influenced by the timeless elegance and charisma of Shah Rukh Khan, the iconic Bollywood superstar. Our mission is simple: to help you discover your unique style identity and feel confident in expressing yourself through fashion.</p>

          <p><b>What We Believe</b></p>

          <p>We believe that style is more than just clothing; it's a reflection of who you are, your personality, and your individuality. Drawing inspiration from Shah Rukh Khan's effortless charm and impeccable fashion sense, we strive to empower our audience to embrace their inner superstar and embrace their personal style journey with confidence and creativity.</p>

          <p> <b>What We Offer</b></p>

          <p>At Srk Style, we offer a diverse range of content, including:</p>

          <p>Fashion Tips & Trends: Stay ahead of the curve with our expertly curated fashion tips, trend forecasts, and style guides.
            Celebrity Style Insights: Get insider access to the latest celebrity fashion looks, red carpet moments, and style inspiration.
            Lifestyle & Culture: Explore the intersection of fashion, lifestyle, and culture, with articles on travel, beauty, wellness, and more.
            Community Engagement: Join our vibrant community of fashion enthusiasts, share your style journey, and connect with like-minded individuals who share your passion for fashion and self-expression.
            Get Involved</p>

          <p> We want to hear from you! Whether you're looking for style advice, have feedback on our content, or simply want to connect with fellow fashion lovers, we're here for you. Follow us on social media, join the conversation, and let's embark on this stylish journey together!</p>

          <p><b>Contact Us</b></p>

          <p>Got a question or suggestion? Feel free to reach out to us at [contact email]. We value your feedback and are always striving to improve your Srk Style experience.
            Thank you for choosing Srk Style as your go-to fashion destination. Let's make every day a stylish one!</p>

          <p>Warm regards,<br />
            The Srk Style Team</p>

        </article>
      </Wrapper>
    </main>
  );
};

export default AboutPage;
