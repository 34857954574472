import React, { useEffect } from 'react';
import Wrapper from './styles';
import { useFilterContext } from '../../context/filter_context';
import { getUniqueValues, formatPrice } from '../../utils/helpers';
import { FaCircle } from 'react-icons/fa';
import { FaCheck } from 'react-icons/fa';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const Filters = (props) => {
  const {
    filters: {
      text,
      company,
      category,
      color,
      min_price,
      max_price,
      price,
      shipping,
    },
    updateFilters,
    clearFilters,
    all_products,
  } = useFilterContext();
  
  console.log("props of my params", props?.myParams?.category);
  // console.log(filters.category, "filters");
  useEffect(() => {
    if (props?.myParams?.category) {
      updateFilters(props?.myParams?.category);
    }
  }, [props?.myParams?.category]);

  const history = useHistory();

  const handleSubmit = (make) => {
    const queryParams = {};
    console.log(make, "make");
    if (make?.length > 0) {
      queryParams["category"] = make;
    }
    const searchParams = new URLSearchParams(queryParams).toString();
    console.log("hello");
    history.push({
      pathname: "/products",
      search: `?${searchParams}`,
    });
  };

  const categories = getUniqueValues(all_products, 'category');
  const companies = getUniqueValues(all_products, 'company');
  const colors = getUniqueValues(all_products, 'colors');

  return (
    <Wrapper>
      <div className='content'>
        <form onSubmit={(e) => e.preventDefault()}>
          {/* search input */}
          {/* <div className='form-control'>
            <input
              type='text'
              name='text'
              placeholder='search'
              className='search-input'
              value={text}
              onChange={updateFilters}
            />
          </div> */}
          {/* end search input */}
          {/* categories input */}
          <div className='form-control'>
            <h5>categories</h5>
            <div>
              {categories.map((item, index) => {
                return (
                  <span style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                    <FaCircle style={{ opacity: "0.2", fontSize: "12px" }} />
                    <button
                      key={index}
                      type='button'
                      name='category'
                      className={`${category === item.toLowerCase() ? 'active' : null
                        }`}
                      onClick={(e) => {updateFilters(e); if(e.target.name === 'category')handleSubmit(e.target.value)}}
                    >
                      {item}
                    </button>
                  </span>

                );
              })}
            </div>
          </div>
          {/* end categories input */}
          {/* companies input */}
          {/* <div className='form-control'>
            <h5>Brands</h5>
            {companies.map((item, index) => {
              return (
                <span style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                  <FaCircle style={{ opacity: "0.2", fontSize: "12px" }} />
                  <button
                    key={index}
                    value={item}
                    type='button'
                    name='company'
                    className={`${category === item.toLowerCase() ? 'active' : null
                      }`}
                    onClick={updateFilters}
                  >
                    {item}
                  </button>
                </span>

              );
            })}
            <select
              name='company'
              id='company'
              className='company'
              value={company}
              onChange={updateFilters}
            >
              {companies.map((item, index) => {
                return (
                  <option key={index} value={item}>
                    {item}
                  </option>
                );
              })}
            </select>
          </div> */}
          {/* end companies input */}
          {/* colors input */}
          <div className='form-control'>
            <h5>Colors</h5>
            <div className='colors'>
              {colors.map((item, index) => {
                if (item === 'all') {
                  return (
                    <button
                      key={index}
                      name='color'
                      data-color='all'
                      className={`${color === 'all' ? 'all-btn active all-color' : 'all-btn'
                        }`}
                      onClick={updateFilters}
                    >
                      all
                    </button>
                  );
                }

                return (
                  <button
                    key={index}
                    name='color'
                    data-color={item}
                    style={{ background: item }}
                    className={`${color === item ? 'color-btn active' : 'color-btn'
                      }`}
                    onClick={updateFilters}
                  >
                    {color === item && <FaCheck />}
                  </button>
                );
              })}
            </div>
          </div>
          {/* end colors input */}
          {/* price input */}
          <div className='form-control color-range' >
            <h5>Price</h5>
            <p className='price'>{formatPrice(price)}</p>
            <input
              type='range'
              name='price'
              id='price'
              min={min_price}
              max={max_price}
              value={price}
              onChange={updateFilters}
              className='custom-range'
            />
          </div>
          {/* end price input */}
          {/* shiping input */}
          <div className='form-control shipping'>
            <label htmlFor='shipping'>Free shipping</label>
            <input
              type='checkbox'
              name='shipping'
              id='shipping'
              checked={shipping}
              onChange={updateFilters}
            />
          </div>
          {/* end shipping input */}
        </form>
        <button type='button' className='clear-btn' onClick={clearFilters}>
          Clear filters
        </button>
      </div>
    </Wrapper>
  );
};

export default Filters;
